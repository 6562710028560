.root-view{
    overflow: hidden;
    .logo {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 22px 24px;
        height: 75px;
        font-family: 'YouSheBiaoTiHei';
        font-style: normal;
        font-weight: 400;
        font-size: 24px;
        line-height: 31px;
        color: #FFFFFF;

    }
    .avatar {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 6px 24px;
        height: 50px;
        background: #222840;
        margin-bottom: 20px;
        .name{
            margin-left: 16px;
            font-family: 'PingFang SC';
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 20px;
            color: #999999;

        }
    }
    .exit{
        position: fixed;
        height: 52px;
        left: 0;
        bottom: 0;
        padding: 6px 24px;
        display: flex;
        flex-direction: row;
        align-items: center;
        .txt {
            margin-left: 8px;
            font-family: 'PingFang SC';
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 52px;
            color: #999999;

        }


    }
}