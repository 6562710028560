
.login {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #E5E5E5;

  //  登录页输入框样式
  .ant-input {
    font-size: 16px;
  }


  .card {
    border-radius: 32px;
    display: flex;
    overflow: hidden;

    .title {
      font-family: 'YouSheBiaoTiHei';
      font-style: normal;
      font-weight: 400;
      font-size: 40px;
      line-height: 52px;
      color: #506EF5;
    }

    .tip1 {
      margin-top: 5px;
      font-family: 'PingFang SC';
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 150%;
      color: #A0A0A0;
    }
    .tip2 {
      margin-top: 5px;
      margin-bottom: 60px;
      font-family: 'PingFang SC';
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 150%;
      color: #A0A0A0;
    }

    .button {
      margin-top: 40px;
      .ant-btn {
        font-size: 18px;
        font-weight: 700;
      }
    }


    .form {
      padding: 90px 54px 0;
      width: 558px;
      background: #FFFFFF;
    }
  }
}