@import '~antd/dist/antd.css';

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

  // 表头自定义样式 第一行
  .ant-table-thead>tr:first-child {
    .ant-table-cell {
      color: #266EFF;
      background-color: #EFF1FF;
    }
  }

  // 表头自定义样式 最后一行
  .ant-table-thead>tr:last-child {
    .ant-table-cell {
      color: #266EFF;
    }
  }

.footer {
  display: flex;
  justify-content: center;
  background-color: white;
  padding: 12px;
  position: fixed;
  width: calc(100vw - 200px);
  left: 200px;
  bottom: 0;
  right: 0;
  border-top: 1px solid #eee;
  z-index: 10;
  .button {
    margin: 0 10px;
    border-radius: 4px;
  }
}
