.search-form {
  .show-more-drawer {
    position: absolute;
    right: .1rem;
    bottom: .4rem;
  }
}
.drawer {
  .drawer-content {
    padding-bottom: 1rem;
  }
  .ant-drawer-body {
    padding: 0 24px;
    .search-section-title {
      font-weight: 700;
      line-height: 1rem;
    }
    .ant-form-item {
      margin-bottom: 0;
    }
    .divider {
      background-color: transparent;
      padding-top: .5rem;
      border-bottom: 1px solid rgba(204, 204, 204, 1);
      margin: 0;
    }
    .ant-select {
      width: 100%;
    }
  }
}
