.red {
  color: red;
}
.green {
  color: green;
}
.blue {
  color: rgb(86, 101, 241);
}
.bold {
  font-weight: 700;
}

.deleted-row {
  background-color: rgba(255, 0, 0, .1);
}
