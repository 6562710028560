.injury {
    position: relative;

    .red {
        color: red;
    }

    .primary {
        color: #266EFF;

        &-bac {
            background-color: #EFF1FF;
        }
    }

    .bold {
        font-weight: 700;
    }

    // 选择器自定义样式
    .ant-select:not(.ant-select-customize-input) .ant-select-selector {
        border-radius: 3px;
    }

    // 输入框自定义样式
    .ant-input {
        border-radius: 3px;
    }

    // table自定义样式
    .ant-table table {
        border-radius: 8px;
    }

    // card 标题
    .card-title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: #266EFF;

        &::before {
            content: "";
            position: absolute;
            border-left: 4px solid #4187ff;
            left: 0px;
            top: 8px;
            height: 20px;
            line-height: 1.15;
        }
    }

    .extra {
        display: flex;
        align-items: center;
        color: #266EFF;
    }

    .table-title {
        background: #EFF1FF;
        color: #266EFF;
    }

    .gray-bac {
        background-color: #F7F8FA;
    }

    .del-btn {
        color: red;
        position: absolute;
        right: 30px;
        top: 6px;
    }

    .standard-summary {
        color: #266EFF;
        background-color: #EFF1FF;
        text-align: center;
    }

    .standard-tip {
        margin: 16px auto;
        color: #999999;
    }

    .standard-table {
        .ant-input-affix-wrapper {
            padding: 4px;
        }

        .ant-input-number-input {
            padding: 0 4px;
        }

        // 行高样式
        .ant-table-thead>tr>th,
        .ant-table-tbody>tr>td,
        .ant-table tfoot>tr>th,
        .ant-table tfoot>tr>td {
            padding: 6px 8px;
        }
    }

}

.finish-pic {
    text-align: center;
}

.agreement {
    min-height: 80vh;
    background: bottom no-repeat url('https://huiyuan-1312091486.cos.ap-beijing.myqcloud.com/6e977488-43c0-e943-c7f3-014aafe4acb6.jpeg');
    background-size: 100% 100%;
    padding: 24px;


    .header {
        margin-bottom: 32px;

        .name {
            display: flex;
            align-items: center;
        }
    }

    .sign {
        display: flex;
        align-items: flex-start;
        margin-bottom: 16px;

        div {
            width: 180px;
            height: 180px;
        }

        img {
            width: 180px;
            vertical-align: text-top;
        }
    }

    .content {
        div.ant-typography {
            text-indent: 2em;
        }
    }

    span.ant-typography {
        text-indent: 0.5em;
        border-bottom: 1px solid #000000;
        border-top: 0px;
        border-left: 0px;
        border-right: 0px;
    }

}